import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/ClassAssembly/2023/5C/1.jpg';
import p2 from 'assests/Photos/ClassAssembly/2023/5C/2.jpg';
import p3 from 'assests/Photos/ClassAssembly/2023/5C/3.jpg';
import p4 from 'assests/Photos/ClassAssembly/2023/5C/4.jpg';
// import p5 from 'assests/Photos/ClassAssembly/2023/5D/5.jpg';
// import p6 from 'assests/Photos/ClassAssembly/2023/5D/6.jpg';
// import p7 from 'assests/Photos/ClassAssembly/2023/5D/7.jpg';
// import p8 from 'assests/Photos/ClassAssembly/2023/5D/8.jpg';


// import p7 from 'assests/Photos/ClassAssembly/6D/7.jpg';



import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly2023';

const ClassAssembly5C2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    // {
    //     src: p5,
    //     source: p5,
    //     rows: 1,
    //     cols: 1,
    //   },
    //   {
    //     src: p6,
    //     source: p6,
    //     rows: 1,
    //     cols: 1,
    //   },

    //   {
    //     src: p7,
    //     source: p7,
    //     rows: 1,
    //     cols: 1,
    //   },
    //   {
    //     src: p8,
    //     source: p8,
    //     rows: 1,
    //     cols: 1,
    //   },

    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      ‘YOGA’
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 5C  Date: 26 JUNE 2023

        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        ‘Meditation relaxes and rejuvenates body and mind’. With this motive, the students of Class 5C presented their 
        class assembly on the theme ‘YOGA’. Motivational thoughts based on yoga were shared on Monday, 26 June 2023 and 
        Tuesday, 27 June 2023, respectively to bring awareness among students. 

 <br></br>
 A dance based on Yoga was performed by the students that displayed different yoga postures and asanas which calm their 
 mind, improve concentration and build endurance. It also reduces their anxiety and restlessness especially during exams. 
 They also emphasised on the regular practice of ‘Surya Namaskar’ that enhances their strength and vitality. 
        <br></br>
        {/* The assembly was well appreciated for its content and presentation. */}
        
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Yoga is journey of the self, through the self, to the self”

<br/>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 250 : 300}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly5C2023;